import React, { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { connectors } from "./config";
import { Modal } from "react-responsive-modal";
import "./Account.css";
import "react-responsive-modal/styles.css";
import { getEllipsisTxt } from "../helpers/formatters";
import useChain from "../../hooks/useChain.js";
import ABI from "../../Contracts/SFV2.json";

const Account = () => {
  const styles = {
    account: {
      height: "42px",
      padding: "0 15px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "fit-content",
      borderRadius: "12px",
      backgroundColor: "rgb(244, 244, 244)",
      cursor: "pointer",
      marginTop: "6px",
      marginleft: "20px",
    },
    text: {
      color: "#FF9900",
      fontSize: "18px",
      fontWeight: "500",
    },
    connector: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      height: "auto",
      justifyContent: "center",
      marginLeft: "auto",
      marginRight: "auto",
      padding: "20px 5px",
      cursor: "pointer",
    },
  };
  const {
    authenticate,
    isAuthenticated,
    account,
    logout,
    Moralis,
    isWeb3Enabled,
    enableWeb3,
    isWeb3EnableLoading,
  } = useMoralis();
  const [open, setOpen] = useState(false);
  const onCloseModal = () => setOpen(false);
  const onOpenModal = () => setOpen(true);
  const [sfv2Balance, setSfv2Balance] = useState(null);
  const { switchNetwork } = useChain();
  const ABISHIB = ABI;

  useEffect(() => {
    const connectorId = window.localStorage.getItem("connectorId");
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading)
      enableWeb3({ provider: connectorId, chainId: 56 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isWeb3Enabled]);

  const balance = async () => {
    if (isAuthenticated) {
      const readOptions = {
        contractAddress: "0x3AE881BBb8b7Aefb095a43Ef1c1FE1C65f91316F",
        functionName: "balanceOf",
        abi: ABISHIB,
        params: {
          account: account,
        },
      };
      const tx = await Moralis.executeFunction(readOptions);
      setSfv2Balance(Number(tx));
    }
  };

  useEffect(() => {
    if (isAuthenticated && account) {
      balance();
    }
  });
  if (!isAuthenticated || !account) {
    return (
      <>
        <button onClick={onOpenModal} className="myButton">
          WalletConnect
        </button>
        <Modal open={open} onClose={onCloseModal} center>
          <div className="connect-wallet">Select Wallet</div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            {connectors.map(({ title, icon, connectorId }, key) => {
              return (
                <>
                  <div
                    style={styles.connector}
                    key={key}
                    onClick={async () => {
                      try {
                        await switchNetwork(0x38);
                        authenticate({
                          provider: connectorId,
                          signingMessage: "Welcome to ShibaFameV2 Web3 App",
                          mobileLinks: connectorId,
                        });
                        window.localStorage.setItem("connectorId", connectorId);
                      } catch (e) {
                        console.error(e);
                      }
                    }}
                  >
                    <img
                      src={icon}
                      alt={title}
                      style={{
                        alignSelf: "center",
                        fill: "rgb(40; 13; 95)",
                        flexShrink: "0",
                        marginBottom: "8px",
                        height: "30px",
                      }}
                    />
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: "15px",
                        color: "white",
                      }}
                    >
                      {title}
                    </p>
                  </div>
                </>
              );
            })}
          </div>
        </Modal>
      </>
    );
  }
  return (
    <div className="account-wrap">
      <span className="accout-bal">
        {" "}
        SFV2:{(Math.round(sfv2Balance) / ("1e" + 9)).toFixed(2)}
      </span>
      <div className="account-row">
        <button
          className="myButton-dc"
          onClick={async () => {
            await logout();
            onCloseModal(true);
            window.localStorage.removeItem("connectorId");
          }}
        >
          Disconnect
        </button>
        <span className="accout-add">{getEllipsisTxt(account, 6)}</span>
      </div>
    </div>
  );
};

export default Account;
