import React, { useEffect } from "react";
import "./TokenTax.css";
import iconTax from "./testlogo.png";
import "aos/dist/aos.css";
import Aos from "aos";
import logoline from "../TokenSupply/line1.png";
const TokenTax = () => {
  useEffect(() => {
    Aos.init({ duration: 2000, throttleDelay: 77 });
  }, []);

  return (
    <div className="body-tax">
      <div className="toks-head" data-aos="fade-down">
        <h1 className="toks-title"> Tokenomics </h1>
        <img alt="logoline" src={logoline} className="toks-line" />
      </div>
      <div className="wrap-sp">
        <div className="cont-sp" data-aos="flip-right">
          <div className="toks-wrapper" data-aos="flip-right">
            <img src={iconTax} alt="Featuers coins" className="toks-logo" />
            <h2 className="toks-h2">BUY TAX</h2>
          </div>
          <div className="toks-wraphead" data-aos="fade-up">
            <p className="toks-p">LP 5%</p>
            <p className="toks-p">Marketing 4%</p>
            <p className="toks-p">Rewards 1%</p>
          </div>
        </div>
        <div className="cont-sp" data-aos="flip-left">
          <div className="toks-wrapper" data-aos="flip-left">
            <img src={iconTax} alt="Featuers coins" className="toks-logo" />
            <h2 className="toks-h2">SELL TAX</h2>
          </div>
          <div className="toks-wraphead" data-aos="fade-up">
            <p className="toks-p">LP 10 %</p>
            <p className="toks-p">Marketing 8%</p>
            <p className="toks-p">Rewards 2%</p>
          </div>
        </div>
      </div>
      <div className="toks-par" data-aos="fade-right">
        <h3 className="toks-h3">
          {" "}
          Hyper Deflationary Token solves inflation, while SHIBAFAME Token looks
          to solve this issue with its Hyper BuyBack and Burn cycle. Buying the
          circulations supply and burning token every quarter.{" "}
        </h3>
      </div>
    </div>
  );
};

export default TokenTax;
