import React, { useEffect }  from 'react'
import logo2 from "./blocklogo.png";
import logoline from "../TokenSupply/line1.png"
import 'aos/dist/aos.css';
import Aos from 'aos'
import "./Vision.css"
const Vision = () => {
  useEffect(() => {
    Aos.init({duration:2000,throttleDelay: 77})
},[]);
    return (
        <div className='body-vision'>
        <div className='vision-container'>
          <div className='vision-col'>
            <h1 className='vision-header'  data-aos="fade-right"> Our Vision</h1>
            <img alt='logoline'src={logoline} className='loggy2' data-aos="fade-left" />
            <h3 className='vision-text' data-aos="fade-right">
              Our purpose is to help create a decentralized financial system by reponsibility coordinating people , value , ownership, and reputation. SHIBA FAME V2 (SFV2) token is aiming to create an ecosystem to increase your asset value as token Building a safe and secure platform to earn a handsome income through staking,NFT staking, App Developement along with maintaining their privacy , security , and automony . We strive to create an ecosystem based on sound monetary policies, with a sound and solid decentralized foundation , that should be expected with the Shiba Fame V2 (SFV2) platform.</h3>
          </div>
          <div className='vision-col2'  data-aos="fade-left" >
              <img src={logo2} alt='shibafameanimation' className='vision-logo'/>
              
           </div>
         
        </div>
      </div>

    );
  };

export default Vision