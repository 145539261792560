import React, { useEffect }  from 'react'
import Aos from 'aos'
import 'aos/dist/aos.css';
import "./OurUtilities.css";


const OurUtilities =() => {
    useEffect(() => {
        Aos.init({duration:2000,throttleDelay: 77})
    },[]);
  return (
    <div className='body2 '>
        <div className='layout-utilities'>
           <h1 className='layout-header'  data-aos="fade-right"> Our Utilities</h1>
           <ul class="details">
       <li className="utilities-li">
          <h6>To Provide Free Education to Indian Community.</h6>
          <p>Educating and creating an awareness to every  Indian about the Blockchain technology, Cryptocurrenies.</p>
       </li>
      <li className="utilities-li">
         <h6>To hunt for real talent</h6>
         <p>India is swiftly gearing towards a US$ 5 trillion economy by 2024. The country is rapidly developing to become a global powerhouse. India is home to a massive consumer base, and the working-age population is soaring. The global corporate arena is swiftly turning to the Indian talent pool due to several push-pull factors like the technique of problem-solving, digital literacy, relentless hard work and the ability to adapt to new technology. Transition is the law of nature, and Indian talent has the potential to circumvent this global transition.</p>
      </li>
    <li className="utilities-li">
        <h6>Creating a Geographical Network to build our Community. </h6>
        <p>Our Vision is to built a vast  community network of holders , India has about 36 States, 750 Districts and around  7 Lakh Villages , Our Vision is to create a Human Blockchain System so as every Indian is directly or indirectly an holder of our Currency  ShibafameV2. As our reward system will automatically generate the passive income to all our holders.</p>
     </li>
     <li className="utilities-li">
        <h6>Launching NFT projects </h6>
        <p>The real talent NFT Projects , will be future core step to our community. The hunt for real talent will lead us to build NFT project on their concepts/designs . We will be the Fundraiser- the platform  as well as the market place for such creative NFT projects  to be listed. The main idea or the main Concept of this platform will be to provide a passive income and a ROYALITY to the First owner of the  NFT .</p>
     </li>
     <li className="utilities-li">
        <h6>QR Codes Projects</h6>
        <p>When we are having a vision to build a Human Blockchain System and Compiling with Decentralized Blockchain Technology, we want to avail a short and simple service for indian community to build a network for farmers, Tribles, Traders, Auto Drivers, Cab Drivers, shopkeepers etc .</p>
     </li>
     <li className="utilities-li">
        <h6>Charity and Donations </h6>
         “ A Good Education is a foundation for Better Future” – Elezibeth Warrens
        <p>Lets Educate India an iniative to Educate Indian Community all over the Districts- Tehsils- Villages . To Make every Indian Educate and aware from the world crypto and Blockchain  technology. To be a competitive nation with regards to other nation and to stand with them as their competitor.</p>
     </li>
    <li className="utilities-li">
       <p>DAO - DAO is the organizations running in the software in a fully democratic way. The of the major features of digital currencies is that they are Decentralized. This means they are not controlled by a single institution like a government or central bank, but instead are divided among a variety of computers, networks, and nodes. In many cases, virtual currencies make use of this decentralized status to attain levels of privacy and security that are typically unavailable to standard currencies and their transactions.</p>
     </li>
    </ul>
        </div>
    </div>
  )
}

export default OurUtilities