import React, { useState } from "react";
import "./navbar.css";
import { GiHamburgerMenu } from "react-icons/gi";
import logo from "./Rounlogo.png";
import { NavLink } from "react-router-dom";
import Account from "../Account/Account";

const Navbar = () => {
  const [showMediaIcons, setShowMediaIcons] = useState(false);

  const clickHandle = event => {
    event.preventDefault()
    setShowMediaIcons(!showMediaIcons)

    console.log('You clicked the button')
}
  return (
    <>
      <nav className="main-nav">
        {/* 1st logo part  */}
        <a className="nav-a" href="/">
        <div className="logo">
      
        <img
          className="logo-img"
          src={logo} alt="logo"
        />
          <h2>
            <span>S</span>hiba
            <span>F</span>ameV2
          </h2>
      
        </div>
        </a>
        {/* 2nd menu part  */}
        <div
          className={
            showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
          }>
          <ul>
            <li>
              <NavLink to="/home">Home</NavLink>
            </li>
            <li>
              <NavLink to="/stakeclubv2">StakingClub</NavLink>
            </li>
            <li>
              <NavLink to="/team">Team</NavLink>
            </li>
            <li>
              <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.shibafamev2.com/whitepaper/WhitepaperV2Edit.pdf"
          >
            WhitePaperV2
              </a>
            </li>
            <li>
            <Account/>
            </li>
        

          </ul>
         
        </div>
   

        {/* 3rd social media links */}
        <div className="social-media">
             
          {/* hamburget menu start  */}
          <div className= { "hamburger-menu" }>
            <a href="/" onClick={clickHandle}>
              <GiHamburgerMenu className="svg-nav" style={{ zindex: "-5", borderRadius: "45px",fill: "#ffc107", background: "black", padding: "5px"}}/>
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
