import React from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import sfv2logo from "./Logoyellow.png";
import Vision from "../Vision/Vision";
import "./Home.css";
import TokenSupply from "../TokenSupply/TokenSupply";
import TokenTax from "../TokenTax/TokenTax";
import Roadmap from "../Roadmap/Roadmap";
import OurFeatures from "../OurFeatures/OurFeatures";
import OurUtilities from "../OurUtilities/OurUtilities";
import line from "./line1.png";

const Home = () => {
  const particlesInit = async (main) => {
    console.log(main);
    await loadFull(main);
  };
  const particlesLoaded = (container) => {
    console.log(container);
  };
  return (
    <div className="home-head">
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          particles: {
            number: {
              value: 80,
              density: {
                enable: true,
                value_area: 1000,
              },
            },
            color: {
              value: "#ffffff",
            },
            shape: {
              type: "circle",
              stroke: {
                width: 0,
                color: "#000000",
              },
              polygon: {
                nb_sides: 2,
              },
              image: {
                src: "img/github.svg",
                width: 100,
                height: 100,
              },
            },
            opacity: {
              value: 0.5,
              random: false,
              anim: {
                enable: true,
                speed: 1,
                opacity_min: 0.1,
                sync: true,
              },
            },
            size: {
              value: 3,
              random: true,
              anim: {
                enable: false,
                speed: 1,
                size_min: 0.1,
                sync: false,
              },
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: "#ffffff",
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 2,
              direction: "none",
              random: false,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: {
                enable: true,
                mode: "grab",
              },
              onclick: {
                enable: true,
                mode: "push",
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 120,
                line_linked: {
                  opacity: 1,
                },
              },
              bubble: {
                distance: 200,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 1,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: true,
        }}
      />
      <div className="home-body">
        <div className="col1">
          <h1 className="sec-title"> SHIBAFAMEV2 </h1>
          <img alt="logo" src={line} className="sec-title-img" />
          <p className="homePar">
            SHIBAFAMEV2 (SFV2) is a community driven, fair launched DeFi Token.
            SHIBAFAMEV2 (SFV2) is a yield-generating social cryptocurrency.
            Members of the SHIBAFAMEV2 (SFV2) Community ecosystem earn interest
            from network activity, all while benefiting the planet though our
            charitable partnerships.
          </p>
          <div className="home-buttonWrapper">
            <a
              className="home-atag"
              href="https://pancakeswap.finance/swap?outputCurrency=0x3AE881BBb8b7Aefb095a43Ef1c1FE1C65f91316F"
              target="_blank"
              rel="noreferrer"
            >
              BUY ON PANCAKE
            </a>
            <a
              className="home-atag"
              href="https://poocoin.app/tokens/0x3AE881BBb8b7Aefb095a43Ef1c1FE1C65f91316F"
              target="_blank"
              rel="noreferrer"
            >
              POOCOIN CHART
            </a>
            <a
              className="home-atag"
              href="https://dxsale.app/app/v3_3/dxlockview?id=0&add=0xdd57C8A43E5E203B3Aa3441c7f4978d359BF9C49&type=lplock&chain=BSC"
              target="_blank"
              rel="noreferrer"
            >
              70% LP LOCKED
            </a>
          </div>
        </div>
        <div className="col2">
          <img src={sfv2logo} alt="logo" className="home-logo" />
        </div>
      </div>
      <Vision />
      <OurFeatures />
      <TokenTax />
      <TokenSupply />
      <Roadmap />
      <OurUtilities />
    </div>
  );
};

export default Home;
