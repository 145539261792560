import React from 'react'
import "./Footer.css"
import { Telegram , Twitter, Youtube, Globe} from 'react-bootstrap-icons'


const Footer = () => {
    return (
     <div className='footer'>
      <div className='cont'>
           <div className='cont-wrapper'>
               <a  href="https://t.me/shibafametoken"> <Telegram className='footer-logo' size={30} fill="#ffc107"/> </a> 
               <a  href="https://twitter.com/shibafame"> <Twitter className='footer-logo' size={30} fill="#ffc107"/></a>
               <a  href="https://www.youtube.com/channel/UCP0TsuKilv-08sNwYEyAaxw"><Youtube className='footer-logo' size={30} fill="#ffc107"/></a>
               <a  href="https://wwww.shibafame.com"> <Globe className='footer-logo'size={30} fill="#ffc107"/></a>
           </div>
         <h3 >Visit Our Social Media Group for more info</h3>
         
      </div>
     </div>
    );
  };
export default Footer