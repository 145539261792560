import React from 'react'
import "./Team.css"
import T1 from "./00.png"
import T2 from "./01.png"
import T3 from "./02.png"
import T4 from "./03.png"
import T5 from "./04.png"
import T6 from "./05.png"
import T7 from "./06.png"
import T8 from "./07.png"
import T9 from "./09.png"
import T10 from "./10.png"
import { Telegram , Twitter} from 'react-bootstrap-icons'
const Contact = () => {
    return (
      <div className='teamBody'>
        <div className='teamlayout'>
          <div className='teamContainer'>
         <h1>Team ShibaFame v2</h1>
           </div>
           <div className='teamWrapper'>
          <div className='teamRow'>
             <div className='teamBox'>
              <div className='teamDp'>
            <img className='teamImg' src={T1} alt='Team Leader'/>  
               </div>  
              <div className='teamInfo'>
              <p> Name: Roshan </p>
              <p> Live: Mumbai, India </p>
              <p> Role: CEO </p>
              <p>Socials: </p>
              <a href="https://t.me/roshan28081990">
                <Telegram className='tgStyle'/>
              </a>
              <a href="https://www.twitter.com/Roshanw28081990">
                <Twitter className='tgStyle'/>
              </a>
              </div>
             </div>  
              <div className='teamBox'>
              <div className='teamDp'>
            <img className='teamImg' src={T2} alt='Team Leader'/>  
               </div>  
              <div className='teamInfo'>
              <p> Name:  Shyamsundar</p>
              <p> Live: Nanded , India</p>
              <p> Role: Team Leader</p>
              <p>Socials: </p>
              <a href="https://t.me/shyamkamble">  
                <Telegram className='tgStyle'/>
              </a>
              <a href="https://www.twitter.com/MugaonkarSb">
                <Twitter className='tgStyle'/>
              </a>
              </div>
              </div>  
               <div className='teamBox'>
              <div className='teamDp'>
            <img className='teamImg' src={T3} alt='Team Leader'/>  
               </div>  
              <div className='teamInfo'>
              <p> Name:  Mahesh </p>
              <p> Live: Pune, India </p>
              <p> Role: Social Media</p>
              <p>Socials: </p>
              <a href="https://t.me/maheshsfv2"> 
                <Telegram className='tgStyle'/>
              </a>
              <a href="https://www.twitter.com/MaheshhWanjari">
                <Twitter className='tgStyle'/>
              </a>
              </div>
               </div>  
               <div className='teamBox'>
              <div className='teamDp'>
            <img className='teamImg' src={T4} alt='Team Leader'/>  
               </div>  
              <div className='teamInfo'>
              <p> Name: Ravi</p>
              <p> Live: Pune , India</p>
              <p> Role: Team Leader</p>
              <p>Socials: </p>
              <a href="https://t.me/ravikolare2015"> 
                <Telegram className='tgStyle'/>
              </a>
              <a href="https://www.twitter.com/kolare_ravi">
                <Twitter className='tgStyle'/>
              </a>
              </div>
          </div>  
          </div> 
          <div className='teamRow'>
               <div className='teamBox'>
              <div className='teamDp'>
            <img className='teamImg' src={T5} alt='Team Leader'/>  
               </div>  
              <div className='teamInfo'>
              <p> Name: Shailesh </p>
              <p> Live: Mumbai , India</p>
              <p> Role: Team Leader</p>
              <p>Socials: </p>
              <a href="https://t.me/Disha772015"> 
                <Telegram className='tgStyle'/>
              </a>
              <a href="https://www.twitter.com/Disha772015">
                <Twitter className='tgStyle'/>
              </a>
              </div>
               </div>  
               <div className='teamBox'>
              <div className='teamDp'>
                 <img className='teamImg' src={T6} alt='Team Leader'/>  
               </div>  
              <div className='teamInfo'>
              <p> Name:  Shaan </p>
              <p> Live: Bidar, India </p>
              <p> Role: Team Leader</p>
              <p>Socials: </p>
              <a href="https://t.me/as_blk"> 
                <Telegram className='tgStyle'/>
              </a>
              <a href="https://www.twitter.com/MohdAzhaan11">
                <Twitter className='tgStyle'/>
              </a>
              </div>
               </div>  
               <div className='teamBox'>
              <div className='teamDp'>
            <img className='teamImg' src={T7} alt='Team Leader'/>  
               </div>  
              <div className='teamInfo'>
              <p> Name: Aakash </p>
              <p> Live: Bhandara, India </p>
              <p> Role: Team Leader </p>
              <p>Socials: </p>
              <a href="https://t.me/aakashsingh333"> 
                <Telegram className='tgStyle'/>
              </a>
              <a href="https://www.twitter.com/aakashsingh463">
                <Twitter className='tgStyle'/>
              </a>
              </div>
               </div>  
               <div className='teamBox'>
                 <div className='teamDp'>
                <img className='teamImg' src={T8} alt='Team Leader'/>  
                 </div>  
                 <div className='teamInfo'>
              <p> Name: :  Babanrao</p>
              <p> Live: Sangli, India </p>
              <p> Role: Team Leader</p>
              <p>Socials: </p>
              <a href="https://t.me/Goodact55">
                <Telegram className='tgStyle'/>
              </a>
              <a href="https://www.twitter.com/NikamBaban">
                <Twitter className='tgStyle'/>
              </a>
                </div>
          </div>  
          </div>   
           </div>
           <div className='devContainer'>
             <h1> Web3 Developers</h1>
          </div>
           <div className='devwrapper'>
           
             <div className='teamRow'>
                 <div className='teamBox'>
                   <div className='teamDp'>
                  <img className='teamImg' src={T9} alt='Team Leader'/>  
                   </div>  
                    <div className='teamInfo'>
                 <p> Name: Cosmo </p>
                 <p> Live: Goa , India</p>
                 <p> Role: Head Developer</p>
                 <p>Socials: </p>
                  <a href="https://t.me/Cosmicknox"> 
                <Telegram className='tgStyle'/>
                  </a>
                  <a href="https://www.twitter.com/CosmoNektr">
                <Twitter className='tgStyle'/>
              </a>
                   </div>
                </div>  
                <div className='teamBox'>
               <div className='teamDp'>
                  <img className='teamImg' src={T10} alt='Team Leader'/>  
               </div>  
                <div className='teamInfo'>
                 <p> Name: Gaurav </p>
                 <p> Live: Pune, India</p>
                 <p> Role: Tech Developer</p>
                 <p>Socials: </p>
                  <a href="https://t.me/indeliblegaurav"> 
                    <Telegram className='tgStyle'/>
                  </a>
                  <a href="https://www.twitter.com/guar4u">
                <Twitter className='tgStyle'/>
              </a>
                </div>
                </div>  
           </div>
        </div>
        </div>
      </div>
    );
  };
  

export default Contact