import React from "react";
import Navbar from "./components/Navbar/Navbar";
import { Switch, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Team from "./components/Team/Team";
import Footer from "./components/Footer";
import StakingClub from "./components/StakingClub/StakingClub";
import "./App2.css"

const App = () => {
return (

    <div className="app">
       <div className="header" style={{position:"sticky", top: "0" , zIndex:"100"}}>
          <Navbar />
       </div>
       <Switch>
         <Route exact path="/">
            <Home />
         </Route>
         <Route exact path="/home">
            <Home />
         </Route>
         <Route path="/stakeclubv2">
           <StakingClub />
         </Route>
         <Route path="/team">
            <Team />
         </Route>
       </Switch>
      <Footer/>
    </div>
  );
};

export default App;
