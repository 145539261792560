import "./StakingClub.css"
import 'bootstrap/dist/css/bootstrap.css';
import React, { useEffect, useState } from 'react';
import logo from "../Navbar/Rounlogo.png"
import icon10 from "./icon10.png"
import icon11 from "./icon11.png"
import icon12 from "./icon12.png"
import icon14 from "./icon14.png"
import Account from "../Account/Account";
import { LoadingOutlined } from '@ant-design/icons';
import { useMoralis ,useWeb3ExecuteFunction, useMoralisQuery} from "react-moralis";
import ABIStake from "../../Contracts/StakingContract.json"
import SFV2ABI from "../../Contracts/SFV2.json"
import { Modal, Spin, Progress} from "antd";
import "antd/dist/antd.css";
const StakingClub = () => {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: "50px",
        color: "#f1b702",
      }}
      spin
    />
  );
const contractProcessor = useWeb3ExecuteFunction();
const CONTRACT_ADDRESS = '0x964aAf3F073ED6f1c4aa8f7a094d5B03544a61BF' // SFV2 Staking Pool Contract Address 
const { isAuthenticated, account, Moralis } = useMoralis();
const [totalStaked , setTotalStaked] = useState(null)

  // staking
const [showStakeModal, setShowStakeModal] = useState(false)
const [stakingLength, setStakingLength] = useState(undefined)
const [stakingPercent, setStakingPercent] = useState(undefined)
const [amount, setAmount] = useState(0)
const [loading, setLoading] = useState(false);
const maxStaked = 10000000000;

const  getTotalStaked = async () => {
  if (isAuthenticated){
  const readOptions = {
    contractAddress: CONTRACT_ADDRESS,
    functionName: "_totalStaked",
    abi: ABIStake,
  };
  const message = await Moralis.executeFunction(readOptions);
  setTotalStaked((parseInt(message)) / Math.pow(10,9));}
  console.log(totalStaked)
}  

useEffect(() => {
  if (isAuthenticated && account) {
    getTotalStaked()}
})

// Get Percenter% of Total Pool
const pp = () => {
  const differnce = maxStaked - totalStaked;
  const per = differnce / maxStaked ;
  const pre = per * 100
 return  (Math.max(100 - pre)).toFixed(4)
}
// Open Staking Modal
const openStakingModal = (stakingLength, stakingPercent) => {
    setShowStakeModal(true)
    setStakingLength(stakingLength)
    setStakingPercent(stakingPercent)
    setAmount(amount)
}
// Close Staking Modal  
const closeStakingModal = () => {
  setShowStakeModal(false)
}
// Approve Staking Function 
const stakeShibaFame =  async () => {
  setLoading(true);
    const wei = amount * 10 ** 9;
    const sendOptions = {
      contractAddress: "0x3AE881BBb8b7Aefb095a43Ef1c1FE1C65f91316F", // SFV2 Contract Address 
      functionName: "approve",
      abi: SFV2ABI,
      params: {
        spender: CONTRACT_ADDRESS,
        amount: wei.toString()
      },
    };    
   await contractProcessor.fetch({
    params: sendOptions,
    onSuccess: () => {
      setLoading(false);
      closeStakingModal();
      succApprove();
     
      setTimeout(function(){
        stake();
     }, 10000);
    },
    onError:(error) => {
      failApprove(error);
      closeStakingModal();
      setLoading(false);
    }
})}
// Approval Sucess Message
function succApprove() {
  let secondsToGo = 5;
  const modal = Modal.success({
    title: "Success!",
    content: `Staking Contract Approved Kindly wait 30 sec while the  Staking Tranaction is loading....`,
  });
  setLoading(true);
  setTimeout(() => {
    modal.destroy();
  }, secondsToGo * 5000);
}
// Approval Error Message
function failApprove(error) {
  let secondsToGo = 5;
  const modal = Modal.error({
    title: "Error!",
    content: error.message,
  });
  setTimeout(() => {
    modal.destroy();
  }, secondsToGo * 2000);
}
// Staking Function 
  const stake = async () => {
    setLoading(true);
    const wei = amount * 10 ** 9
    const op = {
      contractAddress: CONTRACT_ADDRESS,
      abi: ABIStake,
      functionName:"stakeShibaFame",
      params: {
        numDays: stakingLength,
        amount: wei.toString()
  },}
  await contractProcessor.fetch({
   params: op,
    onSuccess: () => {
      setLoading(false);
      succStake();
    setTimeout(function(){
      window.location.reload();
   }, 10000);
    },
    onError: (error) => {
      setLoading(false);
      failStake(error)
     }  
    })
}  
// Staking Sucess Message
function succStake() {
  let secondsToGo = 5;
  const modal = Modal.success({
    title: "Success!",
    content: "Sucessfully Staked SFV2 Tokens ..... app will update your position in 30 sec",
  });
  setTimeout(() => {
    modal.destroy();
  }, secondsToGo * 5000);
}
// Staking Error Message
function failStake(error) {
  let secondsToGo = 5;
  const modal = Modal.error({
    title: "Error!",
    content: error.data["message"],
  });
  setTimeout(() => {
    modal.destroy();
  }, secondsToGo * 2000);
}
// Query For Positions
const queryPositionsId = useMoralisQuery("PositionsId",
query => 
query.limit(1000)
);
const positionsId = JSON.parse(
   JSON.stringify(queryPositionsId.data, [
    "objectId",
     "positionId",
     "walletAddress",
     "createdDate",
     "unlockDate",
     "weiStaked",
     "percentInterest",
     "weiInterest",
     "open",
     "numDays"

   ])
  );
//  Get Positions Ids from Sync Event in the backend   
const getPos = () => {
  if (positionsId){
  console.log(positionsId)
  const results2 = positionsId.filter(
    (e) =>
    e.walletAddress.toLowerCase() === account &&
    e.open === true
    
  )
 return results2
  }
}
// Withdrawal Function
const withdraw = async (positionId) => {
  setLoading(true);
    const readOptions = {
      contractAddress: CONTRACT_ADDRESS,
      functionName: "closePosition",
      abi: ABIStake,
      params: {
        positionId: positionId,
      } 
    };
    await contractProcessor.fetch({
      params: readOptions,
      onSuccess: () => {
        succWithdraw("Withdrawal Successful .....your position will be updated in 30 sec");
        setTimeout(function(){
          window.location.reload();
       }, 10000);
       setLoading(false);
      },
     onError: (error) => {
      setLoading(false);
      failWithdraw(error);
   
     } 
    }) 
}
// Withdraw Sucess Message
function succWithdraw() {
  let secondsToGo = 5;
  const modal = Modal.success({
    title: "Success!",
    content: `Staking Contract Approved Please wait 30 sec for Staking Tranaction`,
  });
  setTimeout(() => {
    modal.destroy();
  }, secondsToGo * 5000);
}
// Withdraw Error Message
function failWithdraw(error) {
  let secondsToGo = 5;
  const modal = Modal.error({
    title: "Error!",
    content: error.data["message"],
  });
  setTimeout(() => {
    modal.destroy();
  }, secondsToGo * 2000);
}
// Update Positions by Id and address
  async function updatePosition (objectId ){
    if (isAuthenticated ){
      const query = new Moralis.Query("PositionsId");
      await query.get(objectId)
      .then((obj) => {
        obj.set("open" , false);
        obj.save()
   })} }
// Calculate Days Remaining Function
const calcDaysRemaining = (unlockDate) => {
    const timeNow = Date.now() / 1000
    const secondsRemaining = unlockDate - timeNow
    return Math.max( (secondsRemaining / 60 / 60 / 24).toFixed(0), 0)
  }
// Calculate Interest Rate Function  
const calInterest = (weiInterest) => {
  const IR = (weiInterest) / ("1e" + 9)
  return (Math.round(IR * 100) / 100).toFixed(0)
}
// Handles users Approve and Stake Amount  
function handleAmount(amount){
    setAmount(amount.target.value)
  }


  if (!isAuthenticated || !account){
    return(
      <div className="apphead">
        <div className="appBody">
            <div className="mark-2">
                <h1>Welcome To ShibaFameV2 StakeClub</h1>
                <Account />
            </div>
        </div>
     </div>
   )}  
  return (
  <div className="marketContainerApp" >   
    <Spin spinning={loading} indicator={antIcon}tip="Loading..." >
      <div className="appBody">
          <div className="stake-prog" onClick={ async() => {await getTotalStaked(); pp() }}> 
           <div className="stake-div" >
             <h2 className="stakepool-h2"> Total SFV2 Staked {totalStaked} of {maxStaked}</h2>
          </div>
          <Progress
             status="active"
             style={{color:"white"}}
             strokeColor={{
             '0%': '#ffec64',
             '100%': '#ffab23',
             }}
             percent={pp()}
             />

          </div>
          <div className="marketContainer">
          <div className="subContainer">
           <span className="marketHeader">SHIBAFAMEV2 STAKE CLUB</span>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div onClick={() => openStakingModal(90, '12%')} className="marketOption">
                <div className="glyphContainer hoverButton">
                 <span className="glyph">
                 <img src={icon10} alt="staking icon" className="stake-logo"/>
                 </span>
                </div>
                <div className="optionData">
                  <span className="optionDays">90days</span>
                  <span className="optionPercent">12%</span>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div onClick={() => openStakingModal(180, '9%')} className="marketOption">
                <div className="glyphContainer hoverButton">
                 <span className="glyph">
                 <img src={icon11} alt="staking icon" className="stake-logo"/>
                 </span>
                </div>
                <div className="optionData">
                  <span className="optionDays">6Months</span>
                  <span className="optionPercent">9%</span>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div onClick={() => openStakingModal(365, '6%')} className="marketOption">
                <div className="glyphContainer hoverButton">
                 <span className="glyph">
                 <img src={icon12} alt="staking icon" className="stake-logo"/>
                 </span>
                </div>
                <div className="optionData">
                  <span className="optionDays">1Year</span>
                  <span className="optionPercent">6%</span>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div onClick={() => openStakingModal(730, '5%')} className="marketOption">
                <div className="glyphContainer hoverButton">
                 <span className="glyph">
                 <img src={icon14} alt="staking icon" className="stake-logo"/>
                 </span>
                </div>
                <div className="optionData">
                  <span className="optionDays">2Years</span>
                  <span className="optionPercent">5%</span>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="assetContainer">
        <div className="subContainer">
          <span className="marketHeader">Staked Assets</span>
        </div>
        <div className="assets-wrap" >
          <div className="columnHeaders">
            <div className="col-md-2 assets-text">Assets</div>
            <div className="col-md-2 assets-text">APY%</div>
            <div className="col-md-2 assets-text">Staked</div>
            <div className="col-md-2 assets-text">Interest</div>
            <div className="col-md-2 assets-text">Unlock</div>
            <div className="col-md-2 assets-text">WithDrawal</div>
          </div>
         </div>
         <br/>
         {getPos().map((a, idx) => (
          <div key ={idx} className="row row-botton">
            <div className="col-md-2 assets-text">
              <span className="logospam">
              <img className="logoImg" src={logo} alt="logo"/>
              </span>
              </div>
              <div className="col-md-2 assets-text unlock">
              {a.percentInterest / 100}% 
              </div>
              <div className="col-md-2 assets-text unlock">
              {Math.round((a.weiStaked) / ("1e" + 9)).toFixed(0)} 
              </div>
              <div className="col-md-2 assets-text unlock">
              {calInterest(a.weiInterest)} 
              </div>
              <div className="col-md-2 assets-text unlock">
              {calcDaysRemaining(a.unlockDate)}days 
              </div>
              <div className="col-md-2 assets-text">
              {a.open ? (
                <div onClick={ async () =>  {await withdraw(a.positionId-1);updatePosition(a.objectId)}} className="myButton"> Withdraw</div>
              ) : (
                <span >closed</span>
              )} 
              </div>
          </div>
         ))}
         </div>
      </div>
      {showStakeModal && (
        <div className="modal-class" stakingLength={stakingLength}  >
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <div className="modal-body">
            <div className="modal-header">
            <h2 className="titleHeader">Stake ShibaFameV2</h2>
            <button className="myButton-X"  onClick={() => closeStakingModal()}>X</button>
            </div>
            <div className="row2">
              <div className="col-md-9 fieldContainer">
                <input
                  className="inputField"
                  placeholder="0.0 SFV2"
                  onChange={handleAmount}
                />
              </div>
            </div>

            <div className="row2">
              <h6 className="titleHeader stakingTerms">{stakingLength} days @ {stakingPercent} APY</h6>
            </div>
            <div className="row2">
              <div
                onClick={() => stakeShibaFame()}
                className="myButton-stake"
              >
                Stake
              </div>
            </div>

          </div>
        </div>
      </div>
      )}
  </Spin>   
  </div>
  )
}

export default StakingClub