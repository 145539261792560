import React,{useEffect} from 'react'
import "./OurFeatures.css"
import icon2 from "./icon-2.png"
import icon3 from "./icon-3.png"
import icon1 from "./icon-1.png"
import icon6 from "./icon-6.png"
import icon4 from "./icon-4.png"
import icon5 from "./icon-5.png"
import 'aos/dist/aos.css';
import Aos from 'aos'


const OurFeatures = () => {
  useEffect(() => {
    Aos.init({duration:2000,throttleDelay: 99})
},[]);
  return (
    <div className='body-feat'>
      <div className='layers-our'>
        <h1 className='heavy' data-aos="fade-down"> Our Features</h1>

        <div className='cont-box'>
          <div className='lay-box'>
                <div className='bag' data-aos="fade-down">
                  <div className='zipper'>
                      <img src={icon3} alt="Featuers coins" className="feat-logos"/>
                      <h3 className="feat-header">Blockchain Network </h3>
                  </div>
                      <li className="feat-text">ShibaFame looks to build bridges between investor and locals community using BlockChain Techonology Apps to development  their localscommunities. </li>
               </div>
               <div className='bag' data-aos="fade-down">
                  <div className='zipper'>
                      <img src={icon6} alt="Featuers coins" className="feat-logos"/>
                      <h3 className="feat-header">Doxxed Team and Contracct Audit</h3>
                  </div>
                      <li className="feat-text">ShibaFame team members are fully doxxed , actively conducting daily group chats for the community and regular Conferences though out India in its initial phase </li>
               </div>
          </div>
          <div className='lay-box'>
                <div className='bag' data-aos="fade-right">
                  <div className='zipper'>
                      <img src={icon1} alt="Featuers coins" className="feat-logos"/>
                      <h3 className="feat-header">100 Bilpons Token Supply</h3>
                  </div>
                      <li className="feat-text">35% Team holding kept for  app rewards supply for new exchanges and marketing . </li>
                     <li className="feat-text">25% Current circulation supply including Locked LP & 40% Token Supply Locked in Dx Sale for 1 year </li>                 
               </div>
               <div className='bag' data-aos="fade-left">
                  <div className='zipper'>
                      <img src={icon2} alt="Featuers coins"className="feat-logos"/>
                      <h3 className="feat-header">Ever Increasing Price Floor</h3>
                  </div>
                      <li className="feat-text">20% of every transaction is transformed into liquidity for Pancakeswap.</li>
                      <li className="feat-text"> 20% Profits from Web 3 App will be used to buyback and burn thus helping ShibaFameV2 price to keep increasing </li>
               </div>
          </div>
          <div className='lay-box'>
                <div className='bag' data-aos="flip-up">
                  <div className='zipper'>
                      <img src={icon4} alt="Featuers coins"className="feat-logos"/>
                      <h3 className="feat-header">Web3 Apppcation Development</h3>
                  </div>
                      <li className="feat-text">DiFi Staking App, NFT Market Place ,BlockChain Learning App</li>
                     <li className="feat-text">10% tax from transactions will be send to marketing wallet to ensure our capabipty in supporting further development and marketing. </li>         
               </div>
               <div className='bag' data-aos="flip-up">
                  <div className='zipper'>
                      <img src={icon5} alt="Featuers coins"className="feat-logos"/>
                      <h3 className="feat-header">Community Driven Project</h3>
                  </div>
                      <li className="feat-text">ShibaFame is perfect example of community building  more then 100 local leader spreading our Roadmap </li>
                      <li className="feat-text"> Holders earns 3% Reflections from each transaction fee collected on PancakeSwap </li>
               </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurFeatures