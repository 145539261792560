import React, { useEffect }  from 'react'
import "./TokenSupply.css"
import Chart from 'react-apexcharts'
import 'aos/dist/aos.css';
import Aos from 'aos'
import logoline from "./line1.png"

const TokenSupply = () => {
  useEffect(() => {
    Aos.init({duration:2000,throttleDelay: 99})
},[]);
   
  return (
    
         <div className='body-suppy'>
           <div className='cont-sp2'>
            <div className='supply-cont'>
               <div className='supply-head'   data-aos="fade-down">
                 <h1 className='supply-h1'> Token Disturbution</h1>
                 <img alt='logoline'src={logoline} className="supply-line"/>
               </div>
               <div className='chart' data-aos="flip-right">
                  <Chart type="donut" width={1420} height={450} series={[5,20,40,35]} options={{
                dataLabels: {
                    style: {
                        fontSize: "14px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: "bold",
                        colors: ["#FF9900"],
                    },
                  },
                  chart: {
                width: 550,
                type: 'donut'
              },
              fill: {
               type:"gadient"
            },
              plotOptions: {
                pie: {
                  startAngle: -90,
                  endAngle: 270
                }
              },
             legend: {
                show: true,
                fontSize: '12px',
                fontWeight: 400,
                position: 'top',
                labels: {
                colors: "white",
                useSeriesColors: false,
                },
              },
              labels:['5% AirDrop','20% Circulation Supply','40% Locked Supply','35% Team'],
              responsive: [{breakpoint: 480,options: {
                  chart: {
                    width: 300
                  }
                }
              }]
            
            }}       
             >
                  </Chart>
              </div>
            </div>
          </div>
        </div>
    
  
  )
}


export default TokenSupply