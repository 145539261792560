import React,{useEffect} from 'react'
import Q3 from "./roadmapq3.png"
import Q4 from "./roadmapq4.png"
import Q1 from "./roadmapq1.png"
import "./Roadmap.css"
import 'aos/dist/aos.css';
import Aos from 'aos'
const Roadmap = () => {
    useEffect(() => {
        Aos.init({duration:2000,throttleDelay: 99})
    },[]);
  return (
    <div className='body-roadmap'>
        <div className='road-head'>
            <h1 className='road-h1'>RoadMap 2022-2023</h1>
        </div>
        <div className='layout3'>
            <div className='layout-cont'>
                <div className='wrapper-main'data-aos="fade-right">
                    <div className='wrap-head'>
                    <img src={Q3} alt="roadmap 3rd quarter" className='road-img'/>
                    <h2 className='road-h2'>Quarter 3 2022</h2>
                    </div>
                    <div className='wrap-span'>
                        <span className="span-road">Migration to V2 Smart Contract </span>
                        <span className="span-road">Listing on Cmc , Coingeko 30 plus votingsite </span>
                        <span className="span-road">Web3 Website Developement and Intergration</span>
                        <span className="span-road">Deployment of Staking Application Beta Mainnet</span>
                        <span className="span-road">New RoadMap and WhitePaper V2.0 </span>
                        <span className="span-road">20K Holders plus</span>
                        <span className="span-road">Advertising Campaigns</span>
                        <span className="span-road">Marketing Fenzy Multiple AMA's</span>
                        <span className="span-road">Multi-channel Marketing and Community Giveaways</span>
                    </div>
                </div>
                <div className='wrapper-main' data-aos="fade-down">
                    <div className='wrap-head'>
                    <img src={Q4} alt="roadmap 4rd quarter" className='road-img'/>
                    <h2 className='road-h2'>Quarter 4 2022</h2>
                    </div>
                    <div className='wrap-span'>
                        <span className="span-road">WhiteListing SHIBAFAME 1st NFT Collection</span>
                        <span className="span-road">PreSale and Public Sale of  NFT Collection</span>
                        <span className="span-road">Beta Testing SHIBAFAMEV2 NFT Market  </span>
                        <span className="span-road">NFT Partnerships Program for Community</span>
                        <span className="span-road">Promotion Giveaways,and Tending Marketing </span>
                        <span className="span-road">Live Community Events and Conferences accross India</span>
                    </div>
                </div>
                <div className='wrapper-main' data-aos="fade-left">
                    <div className='wrap-head'>
                    <img src={Q1} alt="roadmap 1rd quarter" className='road-img'/>
                    <h2 className='road-h2'>Quarter 1 2023</h2>
                    </div>
                    <div className='wrap-span'>
                        <span className="span-road">Launch and Deployment of ShibaFame NFT Minter</span>
                        <span className="span-road">ShibaFame Web3 Application upgrades</span>
                        <span className="span-road">Roapmap For ShibaFame BlockChain Learning App</span>
                        <span className="span-road">ShibaFame BlockChain Learning Videos and Classes </span>
                        <span className="span-road"> Major Bussiness Partnerships </span>
                        <span className="span-road"> Sponserships Announcements for community</span>
                        <span className="span-road"> Global Team Expansion</span>
                        <span className="span-road"> 35K Holder and Telegram memebers</span>
                        <span className="span-road"> Develop the Shiba FameHouse Platform .</span>
                        </div>
                </div>
            </div> 
        </div>
    </div>
  )
}

export default Roadmap